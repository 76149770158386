<template>
  <div class="container">
    <el-card shadow="never" class="card">
      <p class="header">{{ $t('register_card_title') }}</p>
      <div class="content">
        <div class="left">
          <p>{{ $t('register_title') }}</p>
          <el-form
            label-position="top"
            label-width="100px"
            :model="form"
            :rules="rules"
            ref="form"
          >
            <el-form-item :label="$t('register_form1')" prop="name">
              <el-select
                v-model="form.name"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option :label="$t('other_title1')" value="0"></el-option>
                <el-option :label="$t('other_title2')" value="1"></el-option>
                <el-option :label="$t('other_title3')" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('register_form2')" prop="email">
              <el-input
                v-model="form.email"
                :placeholder="$t('register_form2_placeholder')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('register_form3')" prop="phone">
              <el-input
                v-model="form.phone"
                :placeholder="$t('register_form3_placeholder')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('register_form4')" prop="passWord">
              <el-input
                :placeholder="$t('register_form4_placeholder')"
                type="password"
                v-model="form.passWord"
                autocomplete="off"
                :show-password="true"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('register_form5')" prop="checkPassword">
              <el-input
                :placeholder="$t('register_form5_placeholder')"
                type="password"
                v-model="form.checkPassword"
                autocomplete="off"
                :show-password="true"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('register_form6')" prop="code">
              <el-input
                :placeholder="$t('register_form6_placeholder')"
                v-model="form.code"
              >
                <span slot="suffix" class="suffix" @click="handleGetCode">
                  {{
                    isIn60
                      ? ` ${
                          this.$i18n.locale() === 'cn'
                            ? `${count} ${$t('register_code_time')}`
                            : `${$t('register_code_time')} ${count} seconds`
                        }`
                      : $t('register_code_button')
                  }}
                </span>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('register_form7')" prop="recommendCode">
              <el-input
                :placeholder="$t('register_form7_placeholder')"
                v-model="form.recommendCode"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="isChecked"></el-checkbox>
              <a @click="showProtocol = !showProtocol">
                《{{ $t('register_user_agreement') }}》
              </a>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                style="width: 100%"
                :disabled="!this.isChecked"
                @click="handleRegister('form')"
              >
                {{ $t('register_button') }}
              </el-button>
            </el-form-item>
            <el-form-item>
              <span>{{ $t('register_message1') }}</span>
              <span
                style="color: #1890ff; cursor: pointer"
                @click="$router.push('/user/login')"
              >
                {{ $t('register_message2') }}
              </span>
            </el-form-item>
          </el-form>
        </div>
        <div class="right">
          <div>
            <p>
              <span>{{ $t('register_sub_title1') }}</span>
              <span>1.{{ $t('register_sub1_content1') }}</span>
            </p>
            <p>
              <span>{{ $t('register_sub_title2') }}</span>
              <span>1.{{ $t('register_sub2_content1') }}</span>
              <span>2.{{ $t('register_sub2_content2') }}</span>
              <span>3.{{ $t('register_sub2_content3') }}</span>
            </p>
            <p>
              <span>{{ $t('register_sub_title3') }}</span>
              <span>1.{{ $t('register_sub3_content1') }}</span>
              <span>2.{{ $t('register_sub3_content2') }}</span>
              <span>3.{{ $t('register_sub3_content3') }}</span>
            </p>
            <p>
              <span>{{ $t('register_sub_title4') }}</span>
              <span>{{ $t('register_sub4_content1') }}</span>
            </p>
          </div>
        </div>
      </div>
    </el-card>
    <el-dialog
      :visible.sync="showProtocol"
      :title="null"
      style="overflow-y: scroll; height: 99%"
      :close-on-click-modal="false"
    >
      <div class="protocol" v-if="this.$i18n.locale() === 'cn'">
        <p class="header">海纳支付跨境收款协议</p>
        <p>
          《海纳支付跨境收款协议》（以下简称 “本协议” ）由 海纳支付香港有限公司
          Haina Payment HK CO., Limited（以下简称 我司
          ）和您签订，本协议约定了我司向您提供跨境
          收款服务、双方权利义务及其他事项，
          <span class="txt-bold">
            为了保障您的权益，请在使用该服务前，详细阅读
            本协议的所有内容，特别是加粗部分。
          </span>
          当您在我司的网络页面上点击本协议的勾选框（勾
          选框后书写“海纳支付跨境收款协议收款协议
          或类似文字，且页面上同时列明本协议的内 ”
          容或者可以有效展示本协议内容的链接），或以其它合理方式表明您与我司订立了本协议
          时，即表明本协议在您与我司之间成立并生效。
        </p>
        <p class="txt-bold">一、声明与承诺</p>
        <p class="txt-bold">
          1.为了向您提供服务所需，我司会依照合法、正当、必要的原则收集您的信息，并按照
          《跨境收款隐私政策》中的说明使用、保护、保存及对外提供您的信息。
        </p>
        <p>
          2.您使用本跨境收款服务，应基于真实货物贸易背景，我司会对您提供的交易信息等材料
          进行核查，若未通过则不能成为我司用户，我司将不会为您提供以下服务。
        </p>
        <p>
          3.我司不会采集您（作为个人用户）的宗教信仰、基因、指纹、血型、疾病和病史信息以
          及法律、行政法规规定禁止采集的其他个人信息。
        </p>
        <p>
          4.我司承诺尽商业上的合理努力采用安全技术和程序保障用户信息安全，确保上述技术和
          程序安全标准不低于国家法律法规的要求，以防信息的丢失、不当使用等情况的发生，并
          采取合理措施保障向第三方提供用户信息的准确性。
        </p>
        <p class="txt-bold">
          5.在您注册成为海纳支付用户或您以我司允许的其他方式实际使用本服务，即您使用本服
          务，则表示您已充分阅读、理解并接受本协议的全部内容，同意遵循本协议之所有约定。
        </p>
        <p class="txt-bold">
          6.您同意，我司有权对本协议内容进行变更，并以在本网站（我司网站）或其他适当地方
          公告的方式予以公布，无需另行单独通知您；您在本协议内容公告变更后继续使用本服务，
          表示您已充分阅读、理解并接受变更后的协议内容；若您不同意修改后的协议内容，请您
          勿进行下一步操作，您可以联系我司企业以了解更多信息。
        </p>
        <p>
          7.您知悉您本人和您使用海纳支付服务将受到 适用法 强制性规定的约束。
          <span class="txt-bold">
            特此声明，您是
            以专业或商业身份开展活动，您不是以消费者身份签署本协议以及使用海纳支付服务。
          </span>
          您需要全权负责理解和遵守您原籍国/地区的法律、法规，以及与您使用海纳支付服务相关、
          基于账户司法管辖权可能适用于您的法律、法规，与以下监管支付服务相关的法律法规包
          括但不限于：反洗钱和反恐怖组织融资要求、消费者保护、信息保护、反歧视、赌博、虚
          假广告、非法销售或购买或交易任何商品或服务，和相关的进出口活动、税收或外汇交易
          活动。
        </p>
        <p class="txt-bold">二、定义及解释</p>
        <span>在本协议中，下列术语有下列含义:</span>
        <p>
          a)
          <span class="txt-bold">“海纳支付跨境收款服务的提供方”指的是，</span>
          海纳支付香港有限公司 Haina Payment HK CO., Limited，注册地址：SUITE 6
          RM 1208,12/F CHEUNG SHA WAN PLAZA TOWER 2,833 CHEUNG SHA WAN RD KLN
          HONG KONG。
        </p>
        <p>
          b)
          <span class="txt-bold">“跨境收款服务”</span>
          主要服务内容是作为您的收款代理人为您在境外收取结算货物贸易款项
          资金，并通过合作银行将该等款项直接转入您指定的银行账户，或将该等款项进行汇兑并
          将汇兑后的人民币资金转入您指定的银行账户，同时在资金处理过程中履行相应国际收支
          申报等相关地区法律法规所规定（若有），以及合作银行操作规范的手续。如您的资金因
          违反当地法律法规而遭到银行滞留、缓付、拒付，我公司不具有先行垫付的责任。
        </p>
        <p>
          c)
          <span class="txt-bold">“合作方”</span>
          指基于为您提供跨境收款服务的需求，配合我司为您提供服务的合作方，包括
          但不限于境内外合作银行、支付机构及其他合作方。
        </p>
        <p>
          d)
          <span class="txt-bold">“海纳支付”</span>
          指我司通过“海纳支付”软件系统提供的在线支付与收款服务。
        </p>
        <p>
          e)
          <span class="txt-bold">“提现账户”</span>
          指您在海纳支付所指定的相关收款银行账户，即您接收海纳支付账户余额的
          银行账户。
        </p>
        <p>
          f)
          <span class="txt-bold">“工作日”</span>
          指的是在该账户司法管辖区内，除周六、周日和公共节假日外的工作日，在工
          作日，该管辖区内支付的网络基础设施是开放的，且银行正常开展日常业务活动。
        </p>
        <p>
          g)
          <span class="txt-bold">“用户”</span>
          指如无特别说明，使用本服务的自然人（含个体工商户，以下称为
          "个人用户"）和企业（含事业单位、其它组织等，以下称为“企业用户 ”
          ）。如无特别说明，本协议内写 明 您 和 用户 的地方 “ ” “ ” “ ”
          均包括个人用户与企业用户，统称为 用户 。
        </p>
        <p>
          h)
          <span class="txt-bold">“海纳账户”</span>
          指用户按照本协议第四条第 1 款注册使用的账户。
        </p>
        <p>
          i)
          <span class="txt-bold">“信息提供者”</span>
          指我司采集用户信息的人和其他渠道，包括用户本人、合法拥有用户信息
          的主体及其代理机构、媒体等。
        </p>
        <p>
          j)
          <span class="txt-bold">“海纳支付客户端”</span>
          指由海纳支付公司运营的，负责向用户提供全球收款、换汇及全球付
          款业务等服务的金融信息中介服务平台。
        </p>
        <p>
          k)
          <span class="txt-bold">“书面 或 "书面形式"</span>
          指如无特别说明，指以纸质或电子介质记载的、可以有形地表现其
          所记载内容的任何形式。
        </p>
        <p>
          l)
          <span class="txt-bold">"电商平台"</span>
          指的是电子商务市场或第三方销售平台。
        </p>
        <p class="txt-bold">三、缔结方</p>
        <p>
          1.在本协议中，因存在不同地区的收款需求，故您在不同地区的收款账户缔结方会有所不
          同，具体名单见下表。
        </p>
        <p>
          <el-table border style="width: 100%" :data="protocol_data">
            <el-table-column prop="a" label="账户管辖"></el-table-column>
            <el-table-column prop="b" label="缔约方"></el-table-column>
            <el-table-column prop="c" label="地址信息"></el-table-column>
          </el-table>
        </p>
        <p>2. 服务先决条件</p>
        <p>
          （1）您必须年满 18
          岁。您必须有签订合同的完全法律行为能力。如果您代表企业或商业
          组织使用我司服务，您必须获得根据该商业组织的格式文件或您所在国家/地区的法律合法
          授权，有权代表组织同意这些条款和条件。您应当按照相关监管规定办理或者符合开展跨
          境交易或使用本服务所需的资质、登记、审批和条件等，如您未办理或者未符合的，我司
          有权单方终止向您提供服务，造成的损失由您自行承担。
        </p>
        <p>
          （2）除特定情况外，您必须使用我司支付服务处理您自己的账户，而不是代表任何其他
          人或组织进行交易；如有特殊情况，我们可能需要授权方书面授权代理人进行交易以及我
          们可能需要的任何其他文件来协助我们履行合规义务。我司严格禁止在使用我们的服务时
          假冒或虚假地声明与任何个人或组织的联系。
        </p>
        <p>
          （3）当您的信息有更改时，请您务必及时更新以便信息保持完整和准确。我们保留随时
          因任何原因拒绝向个人或组织提供或停止提供我司服务的权力。
        </p>
        <p class="txt-bold">四、 海纳账户</p>
        <p>1.注册以及注销账户相关</p>
        <p>
          为了您能安全高效地使用本服务，
          <span class="txt-bold">
            您须在本网站注册并取得我司分配给您的海纳账户，
          </span>
          并 按照我司的规则进行身份校验。为此您同意：
        </p>
        <p>
          <span>
            (1)
            您同意指定海纳支付作为您的代理人从市场上收取销售货物或者服务的款项资金。
          </span>
          您认
          可并且同意向海纳支付款项构成履行向您完成支付的义务。您同意您将向每一个市场提交
          符合其要求的文件，用于表明海纳是您的代理人，并且要求市场将对于您的应付款项支付
          给海纳支付。
        </p>
        <p>
          (2)您应当准确、完整地提供并及时更新您的身份信息/企业信息及相关资料。如果您提供
          的身份信息/企业信息及相关资料不合法、不真实、不准确、不完整或涉嫌侵犯他人权利或
          不符合监管部门要求的情形，或者您没有及时配合补充相关资料，您对海纳支付服务的使
          用可能会受到部分或全部地限制、暂停或终止。我司就上述行为不承担任何责任，造成的
          损失由您自行承担。
        </p>
        <p>
          (3)您应当准确提供并及时更新您的电子邮件地址、联系电话、联系地址、邮政编码等联系
          方式，以便我司与您进行及时、有效的联系。您应完全独自承担因通过这些联系方式无法
          与您取得联系致使您遭受的任何损失和增加任何费用等不利后果。您理解并同意，您有义
          务保持您提供的联系方式的有效性，如有任何变更应及时通知我司以进行更新，同时您应
          按我司的要求进行相关操作。
        </p>
        <p>
          (4)您应及时更新资料（包括但不限于身份证、护照、工商营业执照、商业登记证等证件和
          其他身份证明文件信息，注册、使用海纳支付账户和本服务的电子邮箱名或手机号码，与
          海纳支付账户绑定的邮箱、手机号码等（如有））。因您未及时更新资料导致的一切后果，
          均应由您自行承担。
        </p>
        <p>
          (5)我司需要对您提交的注册信息进行审核，并在您提交注册信息后 T+2
          个工作日之内审核
          完毕，若审核通过后您会收到我司发送的邮件通知。本跨境收款协议自我司发出邮件通知
          之日起生效，有效期一年，到期后若双方未解除协议的，本协议自动展续。
        </p>
        <p>
          (6)当您不再需要使用我司提供服务时，您可以申请注销您的海纳支付相关账户，申请注销
          账户是否通过将在 T＋2
          个工作日，将通过电话、电子邮件、手机短信、网站站内信等方
          式给您答复，请您应及时予以关注。您申请注销之后，您的资金将不能进入该账户。
        </p>
        <p>
          (7)在下列情况任意情况出现时，如我司要求，您应当积极配合提供您的有效身份证明文件
          或其他必要文件，并允许我司留存该等文件的彩色扫描件，否则您对海纳支付服务的使用
          可能会受到部分或全部的限制、暂停或终止：
        </p>
        <p>1)您要求变更身份信息或您的身份证明文件已过有效期的；</p>
        <p>2)我司认为您使用海纳支付的行为或相关情况出现异常的；</p>
        <p>3)我司认为您的身份资料存在疑点的；</p>
        <p>
          4)我司认为有必要核对或留存您身份证明文件或其他必要文件的其他情形。
        </p>
        <p>
          5)我司可以通过任何合法运营的拥有用户身份校验机制的主体对您进行身份校验。
        </p>
        <p class="txt-bold">2.账户安全</p>
        <p>
          (1)您可以使用本服务是基于您按照本协议的要求注册了海纳支付账户，并已完成身份校验，
          所以您将对使用该账户进行的一切操作负完全的责任，您同意：您应当对能够登录并使用
          您的海纳支付账户，或验证您身份的一切信息和设备（包括但不限于海纳支付账户登录名
          （如有）、海纳支付登录名、任何密码、身份信息、手机号码、email
          地址、电脑等移动
          终端）进行妥善保管。对于因这些信息或设备的披露、泄露、遗失或非授权使用所致的损
          失，由您自行承担。仅就使用本服务而言，您保证不向其他任何人披露、泄露或借出前述
          信息和设备，亦不使用其他任何人的该等信息和设备。
        </p>
        <p>
          (2)基于不同用户对计算机端以及其它电子设备的不同使用习惯，我司可能在您使用具体设
          备时设置不同的账户登录模式及采取不同的措施识别您的身份。
        </p>
        <p>
          (3)您同意：如您发现本协议第四条、第 2
          款、第（1）项所述的信息或设备遗失，被他人
          冒用、盗用，未经您的合法授权被使用之情形，或发生其他可能危及到海纳支付账户安全
          或您的用户信息安全的情形时，您应立即以有效方式通知到我司，向我司申请采取相关措
          施。我司收到您的申请或我司有合理理由认为针对本服务的操作并非出自于您本人时，我
          司可以暂时中止提供相关服务。您不可撤销地授权我司可以根据自行判断将前述情况同步
          给所有利害关系人，以保障您和他人的合法权益。
        </p>
        <p>
          (4)您确保在持续登录我司的网站时段结束时，以正确步骤退出登录并离开网站。我司不能
          亦不会对因您未能遵守本款约定而发生的任何损失、损毁及其他不利后果负责。
        </p>
        <p>
          (5)您应自行对您的海纳支付账户负责，只有您本人方可登录、使用该账户。该账户不可转
          让、不可赠与、不可继承。
        </p>
        <p>3.保密条款</p>
        <p>
          （1）协议一方在履行本协议期间对于所获知的另一方的商业和技术信息及秘密（商业和
          技术秘密包括但不限于交易手续费和服务年费的金额及支付方式、结算方式、接口技术、
          安全协议及证书等）负有保密义务，任何一方不得将已获知的另一方的商业和技术秘密泄
          露给任何第三方。
        </p>
        <p>
          （2）任何一方只能将保密信息和其内容用于本协议项下的目的，不得用于其他目的，本
          款的约定不适用于下列保密信息：a)从披露方获得时，已是公开的；b)从披露方获得前，
          接受方已获知的；c)从有正当权限并不受保密义务制约的第三方获得的。
        </p>
        <p>
          （3）本协议双方因下列原因披露保密信息，不受前款限制：a)
          向本协议双方的董事、监
          事、高级管理人员和雇员及其聘请的会计师、律师、咨询公司披露；b)
          因法律、法规的强 制性规定而披露。
        </p>
        <p>（4） 协议双方应当促使其负责披露信息的人员亦严格遵守本条约定。</p>
        <p class="txt-bold">五、海纳支付服务规则及相关授权</p>
        <p>1.收款服务</p>
        <p class="txt-bold">
          (1)为了您可以正常使用跨境电商收款服务，您需要首先授权我司在电商平台为您的店铺实
          施绑定（店铺绑定申明见附件一）。
        </p>
        <p>
          <span class="txt-bold">
            (2)店铺结算资金入账到达您的海纳支付账户所需的时间不在我司的控制之中。结算资金通
            常在 1-3 个工作日内收到，最多可能需要 5 个工作日或更长时间。
          </span>
          如果在相关平台的指示 结算成功后 5
          个工作日内未收到入账资金的，我司可根据您的要求代表您进行内部调查，
          如因此所产生的相关成本和费用均由您承担（如有）。
        </p>
        <p>
          (3)我司在收到结算资金后，在不迟于下一个工作日内以电子邮件形式及时通知您的资金入
          账情况。
        </p>
        <p>
          <span class="txt-bold">
            (4)您使用本服务进行跨境收款，应基于真实跨境电商交易为背景，并根据我司的要求提供
            身份信息、交易信息等材料进行核验。
          </span>
          如果您的交易出现异常，或者银行对您的交易或者
          账户产生疑问而暂时滞留您的款项，则我司将根据银行的指令要求您提供进一步的信息及
          资料。
        </p>
        <p>
          (5)本服务下结算资金结汇所适用的汇率为海纳支付香港有限公司合作银行所提供的外汇参
          考牌价。
        </p>
        <p>
          (6)您确认并同意，您将独自承担本服务下结算资金的转账所产生的，由海纳支付香港有限
          公司合作方所收取的所有手续费用，具体费用标准以相关业务展示为准。
        </p>
        <p class="txt-bold">
          (7)我司非银行或信贷机构，对您在海纳支付账户余额不支付利息。通过接受本条款和条件，
          您确认海纳支付有权保留对海纳支付银行账户中持有的任何资金产生的任何利益。
        </p>
        <p>
          <span class="txt-bold">
            (8)您需要自行承担您使用我司服务期间的货币贬值、汇率波动及收益损失等风险，
          </span>
          您仅在
          我司代为收取或代为支付款项（不含被有权机关冻结、止付或其他限制的款项）的金额范
          围内享有对该等款项支付、提现的权利，您对所有代为收取或代为支付款项（含被有权机
          关冻结、止付或受限制的款项）产生的收益（如利息和其他孳息）不享有权利。为了更好
          地向您提供服务，我司就该代为收取或代为支付款项产生的收益（如利息和其他孳息）享
          有所有权。
        </p>
        <p>2.提现服务</p>
        <p>
          (1)我司的提现服务一般为公对公业务，不能用作私人之间进行资金转账。我司有权拒绝执
          行任何向不属于您或者您不是该账户的持有人的银行账户的结算指示。您提现请求的最终
          实现，将根据银行的制度或者要求而会有所改变。
        </p>
        <p>
          (2)若您的海纳支付账户状态为正常，您才可以提交提现申请请求，指示我司将部分或全部
          余额（扣除费用后）提现至您要求的账户。关于每笔提现的数额限制，请遵守《国家外汇
          管理局关于规范银行卡境外大额提取现金交易的通知》等相关国家法律法规规定。
        </p>
        <p>
          (3)您必须确保您的提现账户信息准确无误。因您提供不准确的提现账户信息而导致的资金
          转移错误，我司对此概不负责。如果由于您提供的不准确的提现账户信息而导致付款被拒
          绝，您将可能承担相关费用（如有）。
        </p>
        <p>
          (4)若发生违反合规要求或含有误导性的提现申请，我司将采取合理措施，协助您进行追查，
          并在合理可行的情况下追回此类款项，但对于任何无法追回的款项，我司概不负责。
        </p>
        <p>
          (5)一旦您向我司提交请求以执行提现服务并同意从海纳支付账户中扣除提现金额和所有适
          用费用，该提现操作是不可撤销的，您将无法退还已转入您的提现账户中的资金。
        </p>
        <p>
          (6)海纳支付将向您收取一定的跨境收款提现服务费，具体的服务费规则以您的海纳支付跨
          境收款账户中实时显示的相关内容为准，但双方有另行书面约定的情况除外。若海纳支付
          拟调整或更新有关收款服务费规则的，将在您的海纳支付跨境收款账户中发布调整更新后
          的费率，您继续使用海纳支付提供的跨境收款服务则视为您无条件同意海纳支付相应的调
          整变更。您进一步知晓并同意，海纳支付将在结算资金扣除按照上述费率计算的提现服务
          费后汇至您指定的银行账户。
        </p>
        <p>
          (7)我司保留从您的海纳支付账户的结算款中扣除您应付给我司的任何费用的权力，包括但
          不限于所有根据本协议需要支付的费用。
        </p>
        <p>
          (8)我司收到的提现请求将在 0-3
          个工作日内以当日的合作银行的货币汇率进行处理。
        </p>
        <p>
          (9)如果您的提现请求被银行拒绝，或者迟延，我司不承担预先支付的责任。
        </p>
        <p>
          (10)我司在发现本服务被异常使用，或对服务的使用有合理疑义，或对服务的使用有违反
          法律规定或本协议约定，或收到国家外汇管理局等相关国家部门调查通知时，有权拒绝您
          的提现申请，并通过电话、电子邮件、手机短信、网站站内信等方式通知您，您应及时予
          以关注。
        </p>
        <p class="txt-bold">
          3.您同意，基于运行和交易安全的需要，我司可以暂时停止提供全部或部分服务及其功能，
          或提供新的功能或基于政策、业务变化调整已有的功能。在任何服务及功能减少、增加或
          者变化时，若您仍使用本服务，则表示您仍然同意本协议或者变更后的协议。
        </p>
        <p class="txt-bold">六、 相关权利义务使用限制</p>
        <p>
          1.您在使用本服务时应遵守各国/地区相关法律法规，不将本服务用于任何非法目的（例如
          用于洗钱、诈骗等），且不以任何非法方式使用本服务。
        </p>
        <p>
          2.您理解并同意，我司不对因下述任一情况导致的任何损害赔偿承担责任，包括但不限于
          利润、商誉、数据等方面的损失或其他无形损失的损害赔偿（无论我司是否已被告知该等
          损害赔偿的可能性）：
        </p>
        <p>
          (1)如果您违反本协议的明文规定及精神，我司可能对您暂停、中断或终止提供本服务或其
          任何部分，且我司会在法律允许的范围内继续持有、保存您的用户信息与使用本服务的记
          录。
        </p>
        <p>
          (2)在发现本服务被异常使用，或对服务的使用有合理疑义，或对服务的使用有违反法律规
          定或本协议约定之虞时，我司有权不经通知先行暂停或终止您对于本服务使用，并通过电
          话、电子邮件、手机短信、网站站内信等方式通知您，您应及时予以关注。具体可能导致
          暂停或终止服务的情形包括但不限于：
        </p>
        <p>1)根据本协议的约定；</p>
        <p>2)根据法律法规及法律文书的规定；</p>
        <p>3)根据有权机关的要求；</p>
        <p>4)您使用海纳支付服务的行为涉嫌违反国家法律法规及行政规定；</p>
        <p>5)我司自行依据合理判断认为可能产生风险的；</p>
        <p>6)您遭到他人投诉，且对方已经提供了一定证据的；</p>
        <p>
          如您申请恢复服务、解除上述服务中止或限制，您应按我司要求如实提供相关资料、您的
          身份证明及我司要求的其他信息或文件，以便我司进行核实，且我司有权依照自行判断来
          决定是否同意您的申请。您应充分理解您的申请并不必然被允许。您拒绝如实提供相关资
          料及身份证明的，或未通过我司审核的，则您确认我司有权长期对该等账户停止提供服务
          且长期限制该等产品或者服务的部分或全部功能。
        </p>
        <p class="txt-bold">八、 责任范围及责任限制</p>
        <p>1.我司仅对本协议中列明的责任承担范围负责。</p>
        <p>
          2.您理解并同意，因我司根据本协议并依法向第三方提供您的用户信息产生的任何后果和
          风险应由您自行承担，包括但不限于您的贷款
          申请、支付申请被拒绝，您无法获得更优惠
          的交易条件、您的相关信息被他人不法利用。
        </p>
        <p>
          3.您理解并同意，我司提供的服务是符合国家法律法规规范，并且受到中国国家外汇管理
          局等相关机关的监督管理。在我司定期及其不定期受到监管和调查时，您需配合提供相关
          资料。如若您未提供或者拒绝提供导致我司受到处罚的，您需要承担因此而造成我司的全
          部责任。
        </p>
        <p>
          4.为了符合相关法律法规、监管要求，以及为您提供本服务所需，您同意我司在与履行监
          管要求的义务相关、提供本服务所需、或便利本服务提供之目的，向合作银行和/或相关部
          门提供您的身份信息和/或交易信息，或为您进行相应的基本信息填报、以便完成信息申报、
          监管报告并为您提供相关服务。
        </p>
        <p>
          5.您理解并同意就征信服务：信息提供者向我司提供您的不良信息时，信息提供者应当对
          您进行通知（依照法律、行政法规规定公开的不良信息除外），但信息提供者未履行通知
          义务，或者已履行通知义务但未通知到您并不妨碍这些不良信息的提供，对此我司不承担
          任何责任。
        </p>
        <p>
          6.在法律允许的情况下，我司对于与本协议有关或由本协议引起的任何间接的、惩罚性的、
          特殊的、派生的损失（包括业务损失、收益损失、利润损失、商誉损失、使用数据或其他
          经济利益的损失）均不负有任何责任，即使事先已被告知此等损失的可能性。
        </p>
        <p>
          7.
          您承诺，您将自行处理您与您的用户（如有）而产生的任何纠纷或争议，且您将就任何
          该等纠纷或争议而导致的我司或其任何雇员、官员、代理人或关联公司所遭受的任何损失
          或损害向我司支付或其任何雇员、官员、代理人或关联公司进行补偿并使其免受其害。
        </p>
        <p>8.您承诺不会将服务项下的任何权利、义务转让给第三方。</p>
        <p>
          9.我司不介入您与平台用户、交易对象的合作关系以及任何合作上的权利义务关系，也无
          义务承担您与任何合作方的纠纷或损失赔偿责任、法律责任。
        </p>
        <p>
          10.除本协议另有规定外，在任何情况下，我司对本协议所承担的违约赔偿责任总额不超过
          向您收取的当次服务费用总额。
        </p>
        <p class="txt-bold">九、完整协议</p>
        <p class="txt-bold">
          1.为了您更好地使用海纳支付服务，您还需要阅读并遵守海纳支付网站公示的其它规则，
          请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制服务方责任的条款。
        </p>
        <p>
          2.本协议由本协议条款与本网站公示的各项规则组成，相关名词可互相引用参照，如有歧
          义，以本协议条款为准。您对本协议理解和认同，即对本协议所有组成部分的内容理解并
          认同，一旦您使用本服务，您和我司即受本协议所有组成部分的约束。
        </p>
        <p>
          3.本协议部分内容被有管辖权的法院认定为违法或无效的，不因此影响其他内容的效力。
        </p>
        <p class="txt-bold">十、 商标、知识产权、专利的保护</p>
        <p>
          1.我司及关联公司所有系统及本网站上所有内容，包括但不限于著作、图片、档案、资讯、
          资料、网站架构、网站画面的安排、网页设计、中英文表示、logo，均由我司或我司关联
          公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密权利等。
        </p>
        <p>
          2.非经我司或我司关联企业书面同意，任何人不得在任何国家/地区擅自使用、修改、复制、
          公开传播、改变、散布、发行或公开发表本网站程序或内容。
        </p>
        <p>
          3.本合同项下，您所销售的商品或提供的服务（如有）不得侵犯第三方的知识产权，由此
          产生的侵权责任，需由您自行承担。
        </p>
        <p>
          4.尊重知识产权是您应尽的义务，如有违反，您须承担损害赔偿责任，赔偿我司全部损失。
        </p>
        <p class="txt-bold">十一、争议处理</p>
        <p class="txt-bold">
          本协议受中国香港法律管辖，并根据中国香港法律进行解释。如果双方之间出现与本协议
          相关的任何争议、索赔或分歧，包括本协议的违约、终止或有效性，或与根据本协议客观
          确定的任何事项的确定相关的任何争议、索赔或分歧，应首先尝试协商友好解决。协商不
          成的，双方同意交由香港法院，按照香港法院现行仲裁规则进行仲裁并最终解决。
        </p>
        <p class="txt-bold">附件一、店铺绑定条款</p>
        <p>
          若您向海纳支付提供上述授权信息或继续使用跨境收款服务，即被视为授权海纳支付使用
          上述授权获取与您的跨境收款交易相关的交易背景材料以满足相关合规要求。海纳支付承
          诺将对使用该等授权所收集的交易背景材料严格保密，且不会将该等授权用于前述满足合
          规要求之外的任何其他用途。您可以在停止使用跨境收款服务时，通知海纳支付以终止上
          述授权。
        </p>
        <p>
          为此，海纳支付承诺仅在您授权的范围之内获取且仅为向您提供跨境收款服务之目的而使
          用该等交易背景材料。若因海纳支付过错而超出授权范围获取交易背景材料或使用交易背
          景材料并因此对您造成经济损失的，海纳支付将对您所遭受的该等实际经济损失和支出予
          以赔偿，包括但不限于您为保护该等法律权利或进行相应的抗辩而支出的费用、诉讼或仲
          裁费、律师费及保全费等。
        </p>
        <p>
          为了保障您的权益，请在使用该服务前，详细阅读本协议的所有内容，特别是加粗部分。
          当您在我司的网络页面上点击本协议的勾选框（勾选框后书写“海纳支付跨境收款协议收款
          协议
          或类似文字，且页面上同时列明本协议的内容或者可以有效展示本协议内容的链接）
          ” ，
          或以其它合理方式表明您与我司订立了本协议时，即表明本协议在您与我司之间成立并生
          效。
        </p>
        <p>海纳支付香港有限公司</p>
        <p>Haina Payment HK CO., Limited</p>
      </div>
      <div class="protocol" v-else>
        <p class="header">Haina Payment Cross-Border Collection Agreement</p>
        <p></p>
        <p>
          Haina Payment Cross-Border Collection Agreement (hereinafter referred
          to as &quot;this Agreement&quot;) is entered into between Haina
          Payment HK CO., Limited (hereinafter referred to as &quot;we&quot;)
          and you. This Agreement provides for the provision of cross-border
          collection services to you, the rights and obligations of both parties
          and other matters. This Agreement stipulates the provision of
          cross-border payment collection services to you, the rights and
          obligations of both parties and other matters. In order to protect
          your rights and interests, please read all the contents of this
          Agreement in detail, especially the bolded parts, before using this
          service. When you click on the check box of this Agreement on the web
          page of our company (after the check box, write &quot;Haina Payment
          Cross-border Collection Agreement Collection Agreement&quot; or
          similar words, and the content of this Agreement is set out on the
          page at the same time or a link that can effectively display the
          content of this Agreement), or in other reasonable ways to indicate
          that you have entered into this Agreement with our company, that is to
          indicate that This Agreement is established and effective between you
          and us.
        </p>
        <p></p>
        <p>I. Declaration and Commitment</p>
        <p>
          1. In order to provide services to you, our company will collect your
          information in accordance with the principles of lawfulness,
          legitimacy and necessity, and use, protect, save and provide your
          information to the outside world in accordance with the instructions
          in &quot;Cross-border Collections Privacy Policy&quot;.
        </p>
        <p>
          2. Your use of this cross-border collection service should be based on
          the background of real goods trade, our company will verify the
          transaction information and other materials provided by you, if you do
          not pass, then you can not become a user of our company, our company
          will not provide you with the following services.
        </p>
        <p>
          3. Our company will not collect your (as an individual user) religious
          beliefs, genes, fingerprints, blood type, disease and medical history
          information and other personal information that is prohibited by laws
          and administrative regulations.
        </p>
        <p>
          4. Our company undertakes to make commercially reasonable efforts to
          adopt security technologies and procedures to protect the security of
          user information, to ensure that the security standards of the said
          technologies and procedures are not lower than the requirements of
          national laws and regulations, to prevent the loss of information,
          improper use of information and other situations, and to take
          reasonable measures to ensure the accuracy of user information
          provided to third parties.
        </p>
        <p>
          5. When you register as a user of Haina Payments or when you actually
          use the Service in any other way permitted by our company, i.e., when
          you use the Service, you indicate that you have fully read, understood
          and accepted all the contents of this Agreement and agree to follow
          all the agreements of this Agreement.
        </p>
        <p>
          6. You agree that our company has the right to change the content of
          this agreement, and to announce it in this website (our website) or
          other appropriate places, without separate notice to you; you continue
          to use this service after the change of the content of this agreement
          announcement, that you have fully read, understand and accept the
          content of the agreement after the change; if you do not agree with
          the revised content of the agreement, please do not proceed to the
          next step, you If you do not agree with the modified agreement, please
          do not proceed to the next step, you can contact our company for more
          information.
        </p>
        <p>
          7. You are aware that you and your use of Haina Payments services will
          be subject to the mandatory provisions of the &quot;applicable
          law&quot;. You hereby declare that you are acting in a professional or
          business capacity and that you are not entering into this Agreement
          and using the HNA Payment Services as a consumer. You are solely
          responsible for understanding and complying with the laws and
          regulations of your country of origin, as well as those that may apply
          to you based on the jurisdiction of your account, in connection with
          your use of the HNA Payment Services, including, but not limited to,
          laws and regulations governing payment services such as the following:
          anti-money laundering and counter-terrorist financing requirements,
          consumer protection, information protection, anti-discrimination,
          gambling, false advertising, unlawful sale or purchase of, or dealing
          in, any goods or services, and the laws and regulations governing
          payment services. or trading of any goods or services, and related
          import/export activities, taxation or foreign exchange trading
          activities.
        </p>
        <p></p>
        <p>II. definitions and interpretations</p>
        <p>
          In this Agreement, the following terms shall have the following
          meanings:
        </p>
        <p>
          a) &quot;Haina Payment Cross Border Collection Service Provider&quot;
          means Haina Payment HK CO., Limited, with registered office at SUITE 6
          RM 1208, 12/F CHEUNG SHA WAN PLAZA TOWER 2, 833 CHEUNG SHA WAN RD KLN
          HONG KONG. 833 cheung sha wan rd kln hong kong.
        </p>
        <p>
          b) &quot;Cross-border Collection Service&quot; The main service
          content is to act as your collection agent to collect the funds for
          settling the trade payments of goods for you in foreign countries and
          transfer such funds directly to your designated bank account through
          the cooperative bank, or exchange such funds and transfer the RMB
          funds after exchange to your designated bank account, and fulfill the
          corresponding Balance of Payments in the process of handling the
          funds. At the same time, in the process of handling the funds, we
          shall fulfill the procedures stipulated in the relevant regional laws
          and regulations such as balance of payments declaration (if any) and
          the operational norms of the cooperative bank. If your funds are
          detained, delayed or refused by the bank due to violation of local
          laws and regulations, our company shall not have the responsibility to
          advance the funds first.
        </p>
        <p>
          c) &quot;Partners&quot; refers to the partners, including but not
          limited to domestic and foreign cooperative banks, payment
          institutions and other partners, who cooperate with our company to
          provide you with services based on the demand for cross-border
          collection services.
        </p>
        <p>
          d) &quot;Haina Payment&quot; means the online payment and collection
          services provided by our company through the software system
          &quot;Haina Payment&quot;.
        </p>
        <p>
          e) &quot;Withdrawal Account&quot; means the bank account designated by
          you in HinaPay as the relevant receiving bank account, i.e., the bank
          account from which you receive the balance of your HinaPay account.
        </p>
        <p>
          f) &quot;Business Day&quot; means a business day in the jurisdiction
          of the Account, other than Saturdays, Sundays and public holidays, on
          which the network infrastructure for payments in that jurisdiction is
          open and the bank carries out its normal day-to-day business
          activities.
        </p>
        <p>
          g) &quot;Users&quot; means, unless otherwise specified, natural
          persons (including individual businessmen, hereinafter referred to as
          &quot;Individual Users&quot;) and enterprises (including institutions,
          other organizations, etc., hereinafter referred to as &quot;Enterprise
          Users&quot;) who use the Service. &quot;). If not otherwise specified,
          this agreement states that &quot;you&quot; and &quot;users&quot; are
          included in the place of individual users and business users,
          collectively referred to as &quot;users&quot;.
        </p>
        <p>
          h) &quot;Haina Account&quot; means the account registered for use by
          the User in accordance with Article 4, paragraph 1 of this Agreement.
        </p>
        <p>
          i) &quot;Information Provider&quot; means the person who collects the
          User&#x27;s information and other channels, including the User
          himself, the subject who legally owns the User&#x27;s information and
          its agents, the media and so on.
        </p>
        <p>
          j) &quot;Haina Payment Client&quot; means the financial information
          intermediary service platform operated by Haina Payment Company, which
          is responsible for providing users with services such as global
          collection, exchange of foreign currency and global payment services.
        </p>
        <p>
          k) &quot;writing&quot; or &quot;written form&quot; means, unless
          otherwise specified, any form of paper or electronic media that can
          tangibly represent the content of the record.
        </p>
        <p>
          L) &quot;e-commerce platform&quot; means an e-commerce marketplace or
          third-party sales platform.
        </p>
        <p></p>
        <p>III. Concluding Parties</p>
        <p>
          1. For the purposes of this Agreement, the parties to whom you will be
          entering into accounts for the collection of funds in different
          jurisdictions may differ due to the need to collect funds in different
          jurisdictions, as listed in the table below.
        </p>
        <p></p>
        <p></p>
        <p>2. Service Prerequisites</p>
        <p></p>
        <p>
          (1) You must be at least 18 years of age. You must have full legal
          capacity to enter into contracts. If you use our services on behalf of
          a business or commercial organization, you must be legally authorized
          to agree to these terms and conditions on behalf of the organization
          in accordance with the form document of the commercial organization or
          the laws of your country/region. You shall apply for or comply with
          the qualifications, registration, approval and conditions required to
          carry out cross-border transactions or use our services in accordance
          with the relevant regulatory provisions. If you fail to do so or do
          not comply with them, our company has the right to unilaterally
          terminate the provision of services to you, and you shall bear the
          losses caused by you.
        </p>
        <p></p>
        <p>
          (2) Except in specific cases, you must use our payment services to
          process your own account and not to transact on behalf of any other
          person or organization; if there are special circumstances, we may
          need the authorized party to authorize the agent in writing to conduct
          the transaction as well as any other documents we may need to assist
          us in fulfilling our compliance obligations. Our company strictly
          prohibits the use of our services under false pretenses or false
          claims of affiliation with any person or organization.
        </p>
        <p></p>
        <p>
          (3) When your information changes, please be sure to update it so that
          it remains complete and accurate. We reserve the right to refuse to
          provide or stop providing our services to individuals or organizations
          at any time for any reason.
        </p>
        <p></p>
        <p>IV. Haina Account</p>
        <p>1. Registration and account cancellation</p>
        <p>
          In order for you to use the Service in a safe and efficient manner,
          you must register on the Website and obtain a Haina Account assigned
          to you by the Company, and carry out identity verification in
          accordance with the rules of the Company. For this purpose, you agree
          to:
        </p>
        <p>
          (1) You agree to appoint Haina Payments as your agent to collect funds
          from the market for the sale of goods or services. You acknowledge and
          agree that payment to Heiner constitutes fulfillment of the obligation
          to complete payment to you. You agree that you will submit to each
          Marketplace the documentation required by that Marketplace to indicate
          that Heiner is your agent and to request that the Marketplace pay
          Heiner Payments the amounts due to you.
        </p>
        <p>
          (2) You shall provide and promptly update your Identity
          Information/Corporate Information and related data in an accurate and
          complete manner. If the identity information/corporate information and
          related information provided by you are not legal, true, accurate,
          complete or suspected of infringing the rights of others or not in
          compliance with the requirements of the regulatory authorities, or if
          you do not cooperate to supplement the relevant information in time,
          your use of Hina Payment services may be partially or completely
          restricted, suspended or terminated. Our company shall not be
          responsible for the above behavior, and you shall bear the losses
          caused by it.
        </p>
        <p>
          (3) You shall accurately provide and timely update your e-mail
          address, contact telephone number, contact address, postal code and
          other contact information, so that our company can make timely and
          effective contact with you. You shall be fully and solely responsible
          for any losses and additional costs that you may suffer as a result of
          not being able to get in touch with you through these contact details.
          You understand and agree that you are obliged to keep the validity of
          the contact information you provide, and should notify us of any
          changes in order to update it, and you should carry out the relevant
          operations according to the requirements of our company.
        </p>
        <p>
          (4) You should update your information in a timely manner (including,
          but not limited to, ID cards, passports, industrial and commercial
          business licenses, business registration certificates and other
          documents and other identity document information, registration, use
          of Hina Payments account and the Service&#x27;s e-mail name or cell
          phone number, and Hina Payments account bound to the mailbox, cell
          phone number, etc. (if any)). All consequences resulting from your
          failure to update your information in a timely manner shall be borne
          by you.
        </p>
        <p>
          (5) Our company needs to audit the registration information submitted
          by you and complete the audit within T+2 working days after you submit
          the registration information, if the audit is passed you will receive
          an email notification sent by our company. This cross-border
          collection agreement will come into effect on the date of email
          notification from our company, and will be valid for one year, after
          the expiration date, if both parties do not terminate the agreement,
          this agreement will be renewed automatically.
        </p>
        <p>
          (6) When you no longer need to use the services provided by our
          company, you can apply for cancellation of your Haina Payments
          account, application for cancellation of the account will be passed in
          T + 2 working days, will be through the phone, e-mail, cell phone text
          messages, web site site letter, etc. to give you a reply, please pay
          attention to it in a timely manner. After you apply for cancellation,
          your funds will not be able to enter the account.
        </p>
        <p>
          (7) In the event of any of the following circumstances, if requested
          by our company, you shall actively cooperate in providing your valid
          identification documents or other necessary documents, and allow our
          company to retain color scans of such documents, otherwise your use of
          Haina Payment Services may be partially or fully restricted, suspended
          or terminated:
        </p>
        <p>
          1)You request to change your identification information or your
          identification documents have expired;
        </p>
        <p>
          2) Our company believes that your behavior of using Haina Payments or
          the related situation is abnormal;
        </p>
        <p>
          3) Our company believes that there are doubts about your identity
          information;
        </p>
        <p>
          4) Our company believes that it is necessary to verify or retain your
          identity documents or other necessary documents in other cases.
        </p>
        <p>
          5) Our company can verify your identity through any legally operated
          subject with user identity verification mechanism.
        </p>
        <p>2. Account Security</p>
        <p>
          (1) You can use the Service is based on your registration of a Heiner
          Payments account in accordance with the requirements of this
          Agreement, and has completed the identity verification, so you will be
          fully responsible for all the operations carried out using the
          account, and you agree that: you should be able to log in and use your
          Heiner Payments account, or to verify your identity of all the
          information and equipment (including, but not limited to, the login
          name of the account of the Heiner Payments, if any, Heiner Payments),
          the login name, any password, identity information, and any other
          information. login name, any passwords, identification information,
          cell phone numbers, email addresses, computers and other mobile
          terminals). You shall be solely responsible for any damages resulting
          from the disclosure, leakage, loss or unauthorized use of such
          information or equipment. For the sole purpose of using the Service,
          you warrant that you will not disclose, divulge or lend the
          aforementioned information and equipment to any other person, nor use
          such information and equipment of any other person.
        </p>
        <p>
          (2) Based on the different usage habits of different users of
          computers and other electronic devices, our company may set up
          different account login modes and take different measures to recognize
          your identity when you use specific devices.
        </p>
        <p>
          (3) You agree that if you find that the information or equipment
          described in Article 4, paragraph 2, item (1) of this Agreement is
          lost, fraudulently used or stolen by others, or used without your
          legal authorization, or any other situation that may endanger the
          security of the Haina Payments account or the security of your user
          information, you shall immediately notify us in an effective manner,
          and apply for the adoption of the relevant measures to the Division.
          If our company receives your application or if our company has
          reasonable grounds to believe that the operation of this service is
          not from you, our company may temporarily suspend the provision of the
          relevant services. You irrevocably authorize our company to
          synchronize the aforesaid situation to all interested parties
          according to our own judgment, in order to protect the legitimate
          rights and interests of you and others.
        </p>
        <p>
          (4) You shall ensure that you log out and leave the website at the end
          of the period of continuous access to the website by following the
          correct procedure. We cannot and will not be liable for any loss,
          damage or other adverse consequences arising from your failure to
          comply with this paragraph.
        </p>
        <p>
          (5) You are solely responsible for your HNA Payments account, which
          can only be accessed and used by you. The account cannot be
          transferred, gifted or inherited.
        </p>
        <p>3. Confidentiality clause</p>
        <p></p>
        <p>
          (1) During the performance of this agreement, one of the parties shall
          be obliged to keep confidential the commercial and technical
          information and secrets (including but not limited to the amount and
          payment methods of transaction fees and annual service fees,
          settlement methods, interface technologies, security protocols and
          certificates, etc.) of the other party, and none of the parties shall
          disclose the commercial and technical secrets of the other party that
          they have been informed of to any third party.
        </p>
        <p></p>
        <p>
          (2) Either party may only use the Confidential Information and its
          contents for the purposes under this Agreement and may not use them
          for any other purposes, and the covenant of this paragraph shall not
          apply to the following Confidential Information: a) which is already
          public at the time of obtaining it from the disclosing party; b) of
          which the receiving party has been informed before obtaining it from
          the disclosing party; and c) of which it has been obtained from third
          parties having the proper authority and are not subject to the
          obligation of confidentiality.
        </p>
        <p></p>
        <p>
          (3) The disclosure of confidential information by the parties to this
          Agreement for the following reasons shall not be subject to the
          preceding paragraph: a) to the directors, supervisors, officers and
          employees of the parties to this Agreement and the accountants,
          lawyers and consulting firms retained by them; and b) as a result of
          mandatory provisions of laws and regulations.
        </p>
        <p></p>
        <p>
          (4) The parties to this Agreement shall cause their personnel
          responsible for disclosing information to also strictly comply with
          the provisions of this Treaty.
        </p>
        <p></p>
        <p>V. Haina Payment Service Rules and Related Authorizations</p>
        <p>1. Payment collection service</p>
        <p>
          (1) In order for you to use the cross-border e-commerce collection
          service normally, you need to first authorize our company to implement
          binding for your store on the e-commerce platform (see Annex I for
          store binding affirmation).
        </p>
        <p>
          (2) The time it takes for the store settlement funds to be credited to
          your HNA Payment account is not under our control. Settlement funds
          are usually received within 1-3 business days and may take up to 5
          business days or more. If the credited funds are not received within 5
          working days after the successful settlement of the relevant
          platform&#x27;s instructions, our company may, at your request,
          conduct an internal investigation on your behalf, and any related
          costs and expenses incurred as a result shall be borne by you (if
          any).
        </p>
        <p>
          (3) Upon receipt of the settlement funds, our company will notify you
          of the credited funds in a timely manner by e-mail no later than the
          next business day.
        </p>
        <p>
          (4) Your use of the Service for cross-border collection shall be based
          on the background of real cross-border e-commerce transactions and
          shall be verified by providing identity information, transaction
          information and other materials according to the requirements of our
          Company. If your transaction is abnormal, or the bank has doubts about
          your transaction or account and temporarily holds your payment, then
          our company will request you to provide further information and
          materials according to the bank&#x27;s instruction.
        </p>
        <p>
          (5) The exchange rate applicable to the settlement fund settlement
          under this service is the foreign exchange reference rate provided by
          Haina Payments Hong Kong Limited&#x27;s cooperative banks.
        </p>
        <p>
          (6) You acknowledge and agree that you will be solely responsible for
          all handling fees charged by Haina Payments Hong Kong Limited&#x27;s
          partners for the transfer of settlement funds under the Service, and
          that the specific fee standards are subject to the relevant business
          display.
        </p>
        <p>
          (7) We are not a bank or credit institution, and we do not pay
          interest on the balance of your account with Haina Payments. By
          accepting these terms and conditions, you acknowledge that Heiner
          Payments is entitled to retain any interest accrued on any funds held
          in the Heiner Payments bank account.
        </p>
        <p>
          (8) You need to bear the risks of currency depreciation, exchange rate
          fluctuations and loss of earnings during your use of our services. You
          only have the right to pay for and withdraw the money within the
          amount of the money that our company collects or pays on behalf of you
          (excluding the money that has been frozen, stopped, or otherwise
          restricted by the authorized authorities), and you have the right to
          pay and withdraw the money for and from all of the money that we
          collect or pay on behalf of you (including the money that has been
          frozen, stopped, or otherwise restricted by the authorized
          authorities). You do not have the right to the income (such as
          interest and other fruits) generated from all the money received or
          paid on behalf of you (including the money frozen, stopped or
          restricted by the authorized authorities). In order to provide better
          services to you, our company has the right of ownership of the
          proceeds (such as interest and other fruits) generated from the money
          collected or paid on behalf of you.
        </p>
        <p>2. Withdrawal Service</p>
        <p>
          (1) Our withdrawal service is generally a business-to-business service
          and cannot be used for fund transfer between private individuals. We
          reserve the right to refuse to execute any settlement instruction to a
          bank account that does not belong to you or of which you are not the
          holder. The final fulfillment of your withdrawal request will be
          subject to change according to the bank&#x27;s system or requirements.
        </p>
        <p>
          (2) If the status of your HNA Payments account is normal, you can
          submit a withdrawal request to instruct us to withdraw part or all of
          the balance (after deducting fees) to the account you request.
          Regarding the limitation of the amount of each withdrawal, please
          comply with the &quot;Circular of the State Administration of Foreign
          Exchange on the Regulation of Overseas Cash Withdrawal Transactions of
          Large Amounts by Bank Cards&quot; and other relevant national laws and
          regulations.
        </p>
        <p>
          (3) You must ensure that your withdrawal account information is
          accurate. We are not responsible for any errors in the transfer of
          funds due to inaccurate account information provided by you. If a
          payment is rejected due to inaccurate withdrawal account information
          provided by you, you may be liable for the associated costs (if any).
        </p>
        <p>
          (4) In the event of a withdrawal request that violates compliance
          requirements or is misleading, we will take reasonable steps to assist
          you in tracing and, where reasonably practicable, recovering such
          amounts, but we shall not be liable for any irrecoverable amounts.
        </p>
        <p>
          (5) Once you have submitted a request to us to perform the Withdrawal
          Service and agreed to have the Withdrawal Amount and all applicable
          fees deducted from your HNA Payments Account, such Withdrawal
          operation is irrevocable and you will not be able to return the funds
          that have been transferred to your Withdrawal Account.
        </p>
        <p>
          (6) Haina Payments will charge you a certain service fee for
          cross-border collection withdrawals, and the specific rules of the
          service fee shall be based on the relevant content displayed in real
          time in your Haina Payments cross-border collection account, except
          for cases where both parties have agreed otherwise in writing. If Hina
          Payments intends to adjust or update the rules of the relevant
          collection service fees, it will publish the adjusted and updated
          rates in your Hina Payments cross-border collection account, and your
          continued use of the cross-border collection services provided by Hina
          Payments shall be deemed to be your unconditional consent to the
          corresponding adjustment and change by Hina Payments. You further
          understand and agree that Hina Payments will remit the settlement
          funds to your designated bank account after deducting the withdrawal
          service fee calculated according to the above rate.
        </p>
        <p>
          (7) We reserve the right to deduct any fees payable by you to us,
          including but not limited to all fees payable under this Agreement,
          from the settlement funds of your HNA Payments account.
        </p>
        <p>
          (8)Withdrawal requests received by our company will be processed
          within 0-3 business days at the currency exchange rate of the partner
          bank on that day.
        </p>
        <p>
          (9)If your withdrawal request is rejected by the bank, or is delayed,
          our company is not liable for pre-payment.
        </p>
        <p>
          (10)Our company has the right to reject your withdrawal request and
          notify you by phone, e-mail, cell phone text message, website internal
          letter, etc. when we find that the service has been used abnormally,
          or there is a reasonable doubt about the use of the service, or there
          is a violation of the law or the agreement of the service, or we have
          received a notice of investigation from the State Administration of
          Foreign Exchange and other relevant state departments, and you should
          pay attention to it in a timely manner.
        </p>
        <p>
          3. You agree that, based on the operation and transaction security
          needs, our company may temporarily stop providing all or part of the
          services and their functions, or provide new functions or adjust the
          existing functions based on policy and business changes. If you still
          use the service when any service and function is reduced, increased or
          changed, it means that you still agree with this agreement or the
          changed agreement.
        </p>
        <p></p>
        <p>VI. Related Rights and Obligations Use Restrictions</p>
        <p>
          1. You should comply with the relevant laws and regulations of each
          country/region when using the service, and do not use the service for
          any illegal purpose (e.g. for money laundering, fraud, etc.) and do
          not use the service in any illegal way.
        </p>
        <p>
          2. You understand and agree that our company shall not be liable for
          any damages, including but not limited to damages for loss of profits,
          goodwill, data, etc., or other intangible losses (regardless of
          whether or not our company has been informed of the possibility of
          such damages), arising from any of the following situations:
        </p>
        <p>
          (1) If you violate the express provisions and spirit of this
          Agreement, our company may suspend, interrupt or terminate the
          provision of the Service or any part thereof to you, and our company
          will continue to hold and save your user information and records of
          using the Service to the extent permitted by law.
        </p>
        <p>
          (2) In the event that the Service is found to be abnormally used, or
          the use of the Service is reasonably suspected, or the use of the
          Service is in violation of the provisions of the law or the Agreement,
          the Division Company has the right to suspend or terminate the use of
          the Service without notice, and notify you by phone, e-mail, cell
          phone text message, website letter, etc., and you should pay attention
          to this in a timely manner. Specific circumstances that may lead to
          the suspension or termination of services include, but are not limited
          to:
        </p>
        <p>1)According to the agreement of this Agreement;</p>
        <p>2)According to the laws and regulations and legal instruments;</p>
        <p>3)According to the requirements of the authorized authorities;</p>
        <p>
          4)Your behavior of using Haina Payment services is suspected of
          violating national laws, regulations and administrative provisions;
        </p>
        <p>
          5)Our company believes that risks may arise based on its own
          reasonable judgment;
        </p>
        <p>
          6) You have been complained by others and the other party has provided
          certain evidence;
        </p>
        <p>
          If you apply for resumption of services, lifting of the above service
          suspension or restriction, you should truthfully provide the relevant
          information, proof of identity and other information or documents
          required by our company, so that our company can verify, and our
          company has the right to decide whether or not to agree with your
          application in accordance with its own judgment. You should fully
          understand that your application is not necessarily allowed. If you
          refuse to provide the relevant information and proof of identity, or
          if you fail to pass the audit of the Division I, you confirm that the
          Division I have the right to stop providing services to such accounts
          for a long period of time and long-term restriction of some or all of
          the functions of such products or services.
        </p>
        <p></p>
        <p>System Interruption or Failure</p>
        <p>
          Our system can not operate normally due to the following conditions,
          so that you can not use the services, our company does not bear the
          responsibility for damages, the conditions include but are not limited
          to:
        </p>
        <p>
          (1) Our company in this website announcement of the system downtime
          maintenance or upgrade period.
        </p>
        <p>
          (2) Failure of computer hardware and software or telecommunication
          equipment resulting in the inability to transmit data.
        </p>
        <p>
          (3) Due to typhoons, earthquakes, tsunamis, floods, power outages,
          wars, terrorist attacks and other force majeure factors, resulting in
          our company&#x27;s system obstacles can not perform business.
        </p>
        <p>
          (4) Service interruption or delay due to hacker attacks, technical
          adjustments or failures of the telecommunications sector, website
          upgrades, third-party problems and other reasons.
        </p>
        <p></p>
        <p>VIII. Scope of Liability and Limitation of Liability</p>
        <p>
          1. Our company is only responsible for the scope of liability listed
          in this agreement.
        </p>
        <p>
          2. You understand and agree that any consequences and risks arising
          from the provision of your user information to a third party in
          accordance with this Agreement shall be borne by you, including, but
          not limited to, the rejection of your loan application and payment
          application, the inability to obtain more favorable trading
          conditions, and the unlawful use of your relevant information by
          others.
        </p>
        <p>
          3. You understand and agree that the services provided by our company
          are in accordance with national laws and regulations and are subject
          to the supervision and management of the State Administration of
          Foreign Exchange and other relevant authorities. When our company is
          subject to supervision and investigation on a regular basis and from
          time to time, you need to cooperate in providing relevant information.
          If you do not provide or refuse to provide the information and our
          company is penalized, you need to bear the full responsibility of our
          company.
        </p>
        <p>
          4. In order to comply with the relevant laws and regulations,
          regulatory requirements, as well as to provide you with this service,
          you agree that our company in connection with the fulfillment of the
          obligations of the regulatory requirements, the provision of this
          service, or to facilitate the provision of this service for the
          purpose of providing the cooperative banks and / or relevant
          departments to provide you with identity information and / or
          transaction information, or for you to carry out the corresponding
          basic information to fill in, in order to complete the declaration of
          information, supervision and reporting and to provide you with the
          related services.
        </p>
        <p>
          5. You understand and agree that in respect of the credit service:
          when the information provider provides your bad information to our
          company, the information provider shall notify you (except for the bad
          information disclosed in accordance with the laws and administrative
          regulations), but the information provider&#x27;s failure to fulfill
          the notification obligation, or the failure to fulfill the
          notification obligation but notify you does not prevent the provision
          of such bad information, for which our company does not bear any
          responsibility.
        </p>
        <p>
          6. to the extent permitted by law, we shall not be liable for any
          indirect, punitive, special or consequential damages (including loss
          of business, loss of revenue, loss of profits, loss of goodwill, loss
          of use of data or other economic advantage) arising out of or in
          connection with this agreement, even if we have been advised in
          advance of the possibility of such damages.
        </p>
        <p></p>
        <p>
          7. You undertake that you will deal with any disputes or controversies
          arising between you and your User(s) (if any) at your own discretion
          and that you will indemnify and hold us or any of our employees,
          officers, agents or affiliates harmless against any loss or damage
          suffered by us or any of our employees, officers, agents or affiliates
          as a result of any such dispute or controversy.
        </p>
        <p></p>
        <p>
          8. You undertake not to assign any of your rights or obligations under
          the Services to a third party.
        </p>
        <p></p>
        <p>
          9. Our company does not intervene in the cooperative relationship
          between you and the platform users, trading partners and any
          cooperation on the rights and obligations of the relationship, and is
          not obliged to assume responsibility for disputes or damages between
          you and any partner, legal responsibility.
        </p>
        <p>
          10. Except as otherwise provided in this Agreement, in any case, the
          total amount of liability for breach of contract assumed by our
          company under this Agreement shall not exceed the total amount of
          service fees charged to you at the time.
        </p>
        <p></p>
        <p>IX. Complete Agreement</p>
        <p>
          1. In order for you to better use the services of Haina Payments, you
          also need to read and comply with other rules posted on the Haina
          Payments website, please be sure to read and fully understand the
          contents of the terms and conditions, especially the terms and
          conditions exempting or limiting the liability of the service
          provider.
        </p>
        <p>
          2. This Agreement consists of the terms of this Agreement and the
          rules posted on this website, and the relevant terms can be cited in
          reference to each other, and if there is any ambiguity, the terms of
          this Agreement shall prevail. You understand and agree to this
          Agreement, that is, all components of this Agreement understand and
          agree to the content of this Agreement, once you use this service, you
          and our company is subject to the constraints of all components of
          this Agreement.
        </p>
        <p>
          3. Part of this Agreement is recognized by the court of competent
          jurisdiction as illegal or invalid, does not therefore affect the
          effectiveness of other content.
        </p>
        <p></p>
        <p>
          X. Protection of trademarks, intellectual property rights and patents
        </p>
        <p>
          1. All systems of the Company and its affiliates and all contents on
          this website, including but not limited to copyrights, pictures,
          files, information, materials, website structure, website screen
          arrangement, webpage design, Chinese and English representations,
          logos, are owned by the Company or its affiliates in accordance with
          the law and their intellectual property rights, including but not
          limited to trademarks, patents, copyrights, trade secret rights, and
          so on.
        </p>
        <p>
          2. Without the written consent of our company or our affiliates, no
          one is allowed to use, modify, copy, publicly disseminate, change,
          distribute, issue or publicly publish the program or content of this
          website without authorization in any country/region.
        </p>
        <p>
          3. Under this contract, the goods you sell or services you provide (if
          any) shall not infringe the intellectual property rights of a third
          party, and you shall bear the responsibility for any infringement
          arising therefrom.
        </p>
        <p>
          4. Respect for intellectual property rights is your obligation, and if
          you violate it, you shall be liable for damages and compensate our
          company for all losses.
        </p>
        <p></p>
        <p>XI. Dispute Handling</p>
        <p>
          This agreement is governed by the laws of Hong Kong, China, and
          interpreted in accordance with the laws of Hong Kong, China. If there
          is any dispute, claim or difference between the parties related to
          this Agreement, including the default, termination or validity of this
          Agreement, or any dispute, claim or difference related to the
          determination of any matter objectively determined in accordance with
          this Agreement, should first try to negotiate an amicable settlement.
          If negotiation fails, the parties agree that the referral shall be to
          arbitration administered by the Hong Kong International Arbitration
          Center (HKIAC) and shall be finally resolved in accordance with the
          HKIAC Institutional Arbitration Rules in effect at the time of filing
          the Notice of Arbitration. The law applicable to this arbitration
          clause shall be Hong Kong law, the place of arbitration shall be Hong
          Kong, the number of arbitrators shall be three, and the arbitration
          proceedings shall be conducted in English.
        </p>
        <p></p>
        <p></p>
        <p>Annex I. Store Binding Clause</p>
        <p>
          If you provide the above authorization information to Haina Payments
          or continue to use the cross-border payment collection service, you
          are deemed to authorize Haina Payments to use the above authorization
          to obtain the transaction background materials related to your
          cross-border payment collection transactions in order to meet the
          relevant compliance requirements. Haina Payments undertakes to keep
          the transaction background materials collected by using such
          authorization strictly confidential and will not use such
          authorization for any purposes other than meeting the compliance
          requirements mentioned above. You may terminate the above
          authorization by notifying HEINE PAYMENTS when you stop using the
          cross-border collection service.
        </p>
        <p>
          In this regard, HEINE PAYMENT undertakes to obtain and use such
          transaction background materials only within the scope of your
          authorization and only for the purpose of providing you with
          cross-border collection services. In the event that HEINE PAYMENT
          exceeds the scope of authorization to obtain the transaction
          background materials or use the transaction background materials due
          to the fault of HEINE PAYMENT and thus causes economic losses to you,
          HEINE PAYMENT will compensate you for the actual economic losses and
          expenditures you have suffered, including but not limited to the
          costs, litigation or arbitration fees, attorney&#x27;s fees, and
          preservation fees you have incurred in order to protect the legal
          rights or to carry out the corresponding defenses.
        </p>
        <p></p>
        <p>
          In order to protect your rights and interests, please read all the
          contents of this Agreement in detail, especially the bolded parts,
          before using the service. When you click on the checkbox of this
          Agreement (after the checkbox, write &quot;Haina Payment Cross-Border
          Collection Agreement Collection Agreement&quot; or similar words, and
          the content of this Agreement is set out on the page at the same time
          or a link that can effectively display the content of this Agreement)
          on our network page, or in other reasonable ways to indicate that you
          have entered into this Agreement with us, it means that This Agreement
          is established and effective between you and us.
        </p>
        <p></p>
        <p>Haina Payment HK CO.</p>
        <p>Haina Payment HK CO., Limited</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getVerifyCode, getRegister } from './api';

export default {
  name: 'register',
  data() {
    return {
      isIn60: false, // 是否点击获取验证码
      timer: null,
      count: 60,
      isChecked: false,
      form: {
        name: '0',
        email: '',
        phone: '',
        passWord: '',
        checkPassword: '',
        code: '',
        recommendCode: '',
      },
      showProtocol: false,
      protocol_data: [
        {
          a: '香港',
          b: '海纳支付香港有限公司' + 'Haina Payment HK CO.,' + 'Limited',
          c:
            'SUITE 6 RM 1208,12/F CHEUNG SHA WAN' +
            'PLAZA TOWER 2,833 CHEUNG SHA WAN RD KLN' +
            'HONG KONG',
        },
      ],
    };
  },
  methods: {
    handleGetCode() {
      // phone不为空才可以点击
      this.$refs.form.validateField('phone', async err => {
        if (!err) {
          // 不可重复点击
          if (this.isIn60) return;
          this.isIn60 = true;
          // 请求接口
          const _params = {
            phoneNumber: this.form.phone,
            templateId: '8',
          };
          try {
            await getVerifyCode(_params);
            await this.$message.success('发送成功, 请注意查收!');
          } catch (err) {
            this.$message.error('发送失败，请稍后重试！');
          }
          this.timer = setInterval(() => {
            if (this.count > 0) {
              this.count--;
            } else {
              this.count = 60;
              this.isIn60 = false;
              clearInterval(this.timer);
            }
          }, 1000);
        }
      });
    },
    handleRegister() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const _params = {
              ...this.form,
              lang: this.form.name,
            };
            delete _params.checkPassword;
            await getRegister(_params);
            await this.$refs.form.resetFields();
            this.$message.success('注册成功！');
            await this.$router.push('/user/login');
          } catch (err) {
            //this.$message.error(err);
          }
        } else {
          return false;
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    local() {
      return this.$i18n.locale();
    },
    rules() {
      const checkRepeatPass = (rule, value, callback) => {
        if (value !== this.form.passWord) {
          callback(new Error(this.$t('register_form5_validate')));
        } else {
          callback();
        }
      };

      return {
        name: [
          {
            required: true,
            message: '企业注册所在国家/地区不可为空!',
            trigger: 'change',
          },
        ],
        email: [
          {
            required: true,
            message: this.$t('register_form2_placeholder'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('register_form2_validate'),
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: this.$t('register_form3_placeholder'),
            trigger: 'change',
          },
        ],
        passWord: [
          {
            required: true,
            message: this.$t('register_form4_placeholder'),
            trigger: 'change',
          },
          {
            pattern: /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{6,16}$/,
            message: this.$t('register_form4_validate'),
            trigger: 'change',
          },
        ],
        checkPassword: [
          {
            required: true,
            message: this.$t('register_form5_placeholder'),
            trigger: 'blur',
          },
          {
            validator: checkRepeatPass,
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: this.$t('register_form6_placeholder'),
            trigger: 'blur',
          },
        ],
        // recommendCode: [
        //   {
        //     required: true,
        //     message: '请输入客户经理姓名',
        //     trigger: 'blur',
        //   },
        // ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  background-color: #fafafa;
  .el-card {
    border: none;
  }
  height: calc(100% - 72px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 72px 16px 72px;
  .card {
    width: 100%;
    height: 100%;
    .header {
      position: relative;
      height: 60px;
      line-height: 60px;
      width: 100%;
      font-size: 24px;
      color: #333;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 32px;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 8px;
        height: 32px;
        background: #2c68ff;
        top: 14px;
        left: -20px;
      }
    }
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      .suffix {
        color: #1890ff;
        cursor: pointer;
        margin-right: 20px;
      }
      .left {
        width: 600px;
        box-sizing: border-box;
        padding-right: 32px;
        & > p {
          font-size: 24px;
          font-weight: 600;
          height: 60px;
          line-height: 60px;
          text-align: center;
        }
      }
      .right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          width: 100%;
          height: 594px;
          border-left: 1px solid #d8d8d8;
          box-sizing: border-box;
          padding: 56px 0 20px 32px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          & > p {
            display: flex;
            flex-direction: column;
            & > span {
              height: 24px;
              font-size: 14px;
              line-height: 24px;
              text-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
              color: rgba(0, 0, 0, 0.25);
            }
            & > span:first-child {
              display: block;
              font-size: 14px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }
    }
  }
  .protocol {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    .header {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
    .txt-bold {
      font-weight: bold;
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 2px;
}

::v-deep .el-form-item__label {
  padding: 0;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
::v-deep .el-dialog__wrapper {
  overflow: hidden;
}
::v-deep .el-dialog__body {
  overflow: hidden;
  overflow-y: auto;
}
::v-deep .el-dialog {
  margin-top: 1vh !important;
}
</style>
